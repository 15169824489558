











import { Component, Vue, Prop } from 'vue-property-decorator';
import AnalyticsModel from '@/models/Analytics.model';
@Component
export default class BoxAnalitycsLite extends Vue {
  @Prop({ required: true, type: Array })
  groupAnalytics!: AnalyticsModel[];

  @Prop({ type: String, required: true })
  title!: string;
  @Prop({ type: String, required: true })
  color!: string;

  get TotalPurchases() {
    return this.groupAnalytics.reduce((a, b) => a + b.total_payments, 0);
  }
  get TotalRefunds() {
    return this.groupAnalytics.reduce((a, b) => a + b.payments_refunds, 0);
  }
}
