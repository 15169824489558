













































import { Component, Vue } from 'vue-property-decorator';
import { auth } from '@/plugins/firebase';
import { Action } from 'vuex-class';
import UserTypes from '@/store/types/UserTypes';

@Component({
  components: {},
})
export default class Access extends Vue {
  accessType = 'login';
  formUserEmail = '';
  formUserPassword = '';
  formUserSecretCode = '';
  errorText = '';

  @Action(`userAuthStore/${UserTypes.actions.UPDATEUSER}`) updateUserStore: any;

  get IsDisabledButton(): boolean {
    return this.validateEmail(this.formUserEmail) == false || this.validatePassword(this.formUserPassword) == false;
  }

  async validateForm() {
    this.errorText = '';
    const isValidEmail = this.validateEmailAndCatchError(this.formUserEmail);
    const isValidPassword = this.validatePassword(this.formUserPassword);
    if (isValidEmail && isValidPassword) {
      this.constructorAuthAccessService()
        .then(async (user: firebase.auth.UserCredential) => {
          if (user) {
            await this.updateUserStore(user.user);
            this.$router.replace({ name: 'Dashboard' });
          }
        })
        .catch(reason => {
          this.ExceptionsParser(reason);
        });
    }
  }

  validatePassword(key: string) {
    return key.length >= 5;
  }

  validateEmailAndCatchError(email: string) {
    return this.validateEmail(email);
  }

  validateEmail(email: string) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  constructorAuthAccessService() {
    if (this.accessType == 'login') {
      return this.signIn();
    } else if (this.accessType == 'signup') {
      return this.signUp();
    } else {
      throw new Error('error access type:' + this.accessType);
    }
  }

  signIn() {
    return auth.signInWithEmailAndPassword(this.formUserEmail, this.formUserPassword);
  }

  signUp() {
    return auth.createUserWithEmailAndPassword(this.formUserEmail, this.formUserPassword);
  }

  ExceptionsParser(e: any) {
    this.errorText = e.message || e.code || 'error...';
  }
}
