











import { Component, Vue } from 'vue-property-decorator';
import BoxAnalitycsLite from './BoxAnalitycsLite.vue';

import AnalyticsRepository from '@/repository/modules/analytics.repository';
import { DateTransformerUtil, RangeDates } from '@/plugins/dateUtil';
import AnalyticsModel from '@/models/Analytics.model';

@Component({
  components: {
    BoxAnalitycsLite,
  },
})
export default class SectionInfoBlocks extends Vue {
  private revenueToday: AnalyticsModel[] = [];
  private revenueYesterday: AnalyticsModel[] = [];
  private revenueLast7Days: AnalyticsModel[] = [];
  private revenueLast30Days: AnalyticsModel[] = [];

  mounted() {
    this.getTodayAnalytics();
    this.getYesterdayAnalytics();
    this.getLast7DaysAnalytics();
    this.getLast30DaysAnalytics();
  }

  async getTodayAnalytics() {
    const d = DateTransformerUtil.getToday();
    this.revenueToday = await this.getMetrics(d);
  }
  async getYesterdayAnalytics() {
    const d = DateTransformerUtil.getLastDayRange();
    this.revenueYesterday = await this.getMetrics(d);
  }
  async getLast7DaysAnalytics() {
    const d = DateTransformerUtil.getLast7days();
    this.revenueLast7Days = await this.getMetrics(d);
  }
  async getLast30DaysAnalytics() {
    const d = DateTransformerUtil.getLast30days();
    this.revenueLast30Days = await this.getMetrics(d);
  }

  async getMetrics(date: RangeDates): Promise<AnalyticsModel[]> {
    const { analytics } = await AnalyticsRepository.getAnalytics({
      payment_currency: '',
      payment_country: '',
      payment_platform: '',
      product_id: '',
      from: DateTransformerUtil.dateToString(date.from),
      to: DateTransformerUtil.dateToString(date.to),
      dateType: 'default',
      groupBy: 'default',
    });
    return analytics;
  }
}
