





































import { Component, Vue, Watch } from 'vue-property-decorator';

import AnalyticsRepository from '@/repository/modules/analytics.repository';
import { AnalyticsModelImpl } from '@/models/Analytics.model';
import { DateTransformerUtil, RangeDates } from '@/plugins/dateUtil';

@Component
export default class SectionTableAnalytics extends Vue {
  date = [
    `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`,
    `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate() + 1}`,
  ];
  currency = '';
  country = null;
  menu = false;
  rowsAnalitycs: AnalyticsModelImpl[] = [];
  tableHeader: {}[] = [];

  @Watch('currency')
  onPropertyChanged() {
    this.getDataAnalytics();
  }

  @Watch('country')
  onChangeCountry() {
    this.getDataAnalytics();
  }

  mounted() {
    this.getDataAnalytics();
  }

  get dateRangeText() {
    return this.date.join(' / ');
  }

  async getDataAnalytics() {
    const { analytics } = await AnalyticsRepository.getAnalytics({
      payment_currency: this.currency,
      payment_country: this.country ?? '',
      payment_platform: '',
      product_id: '',
      from: this.date[0],
      to: this.date[1],
      dateType: 'default',
      groupBy: 'days',
    });
    this.rowsAnalitycs = analytics;
    console.log(analytics);
  }

  get getTableHeader() {
    if (this.rowsAnalitycs[0]) {
      const tableHeader: { text: string; value: string }[] = [];
      const keys = Object.keys(this.rowsAnalitycs[0]);
      if (keys.includes('date')) tableHeader.push(this.generateRowHeader('date'));
      if (keys.includes('total_users')) tableHeader.push(this.generateRowHeader('total_users'));
      if (keys.includes('payment_country')) tableHeader.push(this.generateRowHeader('payment_country'));
      if (keys.includes('total_payments')) tableHeader.push(this.generateRowHeader('total_payments'));
      tableHeader.push(this.generateRowHeader('conversion_rate'));
      if (keys.includes('payments_amount')) tableHeader.push(this.generateRowHeader('payments_amount'));
      if (keys.includes('payments_refunds')) tableHeader.push(this.generateRowHeader('payments_refunds'));
      if (keys.includes('refunds_amount')) tableHeader.push(this.generateRowHeader('refunds_amount'));
      tableHeader.push(this.generateRowHeader('totals'));

      return tableHeader;
    } else {
      return [];
    }
  }

  generateRowHeader(keyName: string) {
    switch (keyName) {
      case 'conversion_rate':
        return {
          text: 'CR %',
          value: keyName,
          align: 'end',
        };
      case 'total_users':
        return {
          text: 'Customers',
          value: keyName,
          align: 'end',
        };
      case 'payments_amount':
        return {
          text: 'Income',
          value: keyName,
          align: 'end',
        };
      case 'currency':
        return {
          text: 'Currency',
          value: keyName,
          align: 'end',
        };
      case 'payment_country':
        return {
          text: 'Country',
          value: keyName,
          align: 'end',
        };
      case 'total_payments':
        return {
          text: 'Purchases',
          value: keyName,
          align: 'end',
        };
      case 'payments_refunds':
        return {
          text: 'Charge Back',
          value: keyName,
          align: 'end',
        };
      case 'date':
        return {
          text: 'Date',
          value: keyName,
        };
      case 'refunds_amount':
        return {
          text: 'Refunds Amount',
          value: keyName,
          align: 'end',
        };
      case 'totals':
        return {
          text: 'Total:',
          value: keyName,
          align: 'end',
        };
      default:
        return {
          text: 'Error',
          value: '',
          align: 'end',
        };
    }
  }

  get calculateRowsAnalytics() {
    return this.rowsAnalitycs.map(row => {
      return {
        ...row,
        payments_amount: row.getPaymentAmount(),
        refunds_amount: row.getRefundsAmount(),
        totals: row.getTotal(),
        conversion_rate: row.getConversionRate(),
      };
    });
  }
}
