
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import VueCharts, { Bar } from 'vue-chartjs-typescript';
import AnalyticsModel from '@/models/Analytics.model';

@Component({
  extends: Bar,
})
export default class BarChart extends Vue {
  public renderChart!: (...arg: any) => void;

  @Prop({ required: true })
  objectChart!: { labels: []; datasets: [] };

  @Watch('objectChart')
  onPropertyChanged() {
    console.log(this.objectChart);
    this.renderChartView();
  }

  renderChartView() {
    this.renderChart(
      {
        labels: this.objectChart.labels,
        datasets: this.objectChart.datasets,
      },
      {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          align: 'center',
          position: 'top',
          fontSize: 10,
          labels: {
            usePointStyle: true,
          },
        },
        scales: {
          yAxes: [
            {
              stacked: false,
              gridLines: {
                display: true,
                color: 'rgba(63,81,181,.1)',
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
        },
      },
    );
  }
}
