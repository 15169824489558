export interface AnalyticsModelImpl {
  payments_amount: number;
  total_payments: number;
  currency: string;
  payments_refunds: number;
  refunds_amount: number;
  payment_country?: string;
  date?: string;
  total_users: number;
  getPaymentAmount: () => string;
  getRefundsAmount: () => string;
  getTotal: () => string;
  getConversionRate: () => string;
}

export class AnalyticsModelDTO implements AnalyticsModelImpl {
  payments_amount = 0;
  total_payments = 0;
  currency = '';
  payments_refunds = 0;
  refunds_amount = 0;
  total_users = 0;
  getPaymentAmount = () => '0';
  getRefundsAmount = () => '0';
  getTotal = () => '0';
  getConversionRate = () => '0';
}

export default class AnalyticsModel extends AnalyticsModelDTO {
  constructor(dto: AnalyticsModelDTO) {
    super();
    Object.assign(this, dto);
  }

  getPaymentAmount = () => `${this.generatePriceFormat((this.payments_amount / 100).toFixed(2))} ${this.currency}`;
  getRefundsAmount = () => `-${this.generatePriceFormat((this.refunds_amount / 100).toFixed(2))} ${this.currency}`;

  getTotal = () =>
    `${this.generatePriceFormat(((this.payments_amount - this.refunds_amount) / 100).toFixed(2))} ${this.currency}`;

  getConversionRate = () => `${((100 * this.total_payments) / this.total_users).toFixed(2)} %`;

  private generatePriceFormat = (amount: string) => amount.replace(/\d(?=(\d{3})+\.)/g, '$&,');
}
