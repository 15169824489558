

















import { Component, Vue } from 'vue-property-decorator';
import { auth } from '@/plugins/firebase';
import { Action } from 'vuex-class';
import UserTypes from '@/store/types/UserTypes';
//
import SectionInfoBlocks from '@/components/Home/SectionInfoBlocks.vue';
import BoxChartView from '@/components/Home/BoxChartView.vue';
import SectionTableAnalytics from '@/components/Home/SectionTableAnalytics.vue';

@Component({
  components: {
    SectionInfoBlocks,
    BoxChartView,
    SectionTableAnalytics,
  },
})
export default class Home extends Vue {
  @Action(`userAuthStore/${UserTypes.actions.LOGOUTUSER}`) logOutUser!: void;
}
