export interface RangeDates {
  from: Date;
  to: Date;
}
export const DateTransformerUtil = {
  getLast7days(): RangeDates {
    const now = this.getDateNow();
    const from = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
    const to = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    return { from: from, to: to };
  },
  getLast30days(): RangeDates {
    const now = this.getDateNow();
    const from = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate() - 1);
    const to = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
    return { from: from, to: to };
  },

  getLast24h(): RangeDates {
    const now = this.getDateNow();
    now.setHours(now.getHours() - 24);
    const toDate = this.getDateNow();
    return { from: now, to: toDate };
  },

  getToday(): RangeDates {
    const now = this.getDateNow();
    const fromDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const toDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
    return { from: fromDate, to: toDate };
  },

  getLastDayRange(): RangeDates {
    const now = this.getDateNow();
    const fromDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
    const toDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    return { from: fromDate, to: toDate };
  },

  getLastYearRange(): RangeDates {
    const now = this.getDateNow();
    const fromDate = new Date(now.getFullYear(), now.getMonth() - 12, 1);
    const toDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    return { from: fromDate, to: toDate };
  },

  /**
   *
   * @param date "YYYY-MM"
   */
  getCompleteMonth(date: string): RangeDates {
    const now = this.stringToDate(`${date}-01`);
    const fromDate = new Date(now.getFullYear(), now.getMonth(), 1);
    const toDate = new Date(now.getFullYear(), now.getMonth() + 1, 1);
    return { from: fromDate, to: toDate };
  },

  /**
   *
   * @param date "YYYY-MM-DD"
   */
  stringToDate(date: string): Date {
    const year = date.split('-')[0];
    const month = date.split('-')[1];
    const day = date.split('-')[2];
    return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
  },
  /**
   *
   * @param date "new Date()"
   */
  dateToString(date: Date): string {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  },

  getDateNow(): Date {
    return new Date();
  },
};
