import API from '../repository';
import AccountingModel, { AccountingModelDTO } from '@/models/Accounting.model';
import AnalyticsModel, { AnalyticsModelDTO } from '@/models/Analytics.model';

const RESOURCE = '/analytics';

export default {
  async getAnalytics({
    payment_currency = '',
    payment_country = '',
    payment_platform = '',
    product_id = '',
    from = '',
    to = '',
    dateType = '',
    groupBy = '',
  }): Promise<{ analytics: AnalyticsModel[] }> {
    const { analytics }: { analytics: AnalyticsModelDTO[] } = await API.post(`${RESOURCE}`, {
      payment_currency,
      payment_country,
      payment_platform,
      product_id,
      from,
      to,
      dateType,
      groupBy,
    });

    const analyticsModels = analytics.map(a => new AnalyticsModel(a));

    return {
      analytics: analyticsModels,
    };
  },
};
